import { useQuery } from '@tanstack/react-query';
import { getFilterOptions } from '../services/inputDataService';

/**
 * The handeling of api data
 *
 * @param {object} runId // The run ID of the current run
 *
 * @return {Promise}
 */
export function useFilters(runId) {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['useFilters', runId],
    queryFn: async () => {
      return getFilterOptions(runId);
    },
    select: (data) => {
      if (Array.isArray(data)) {
        return {
          // Format date and remove the time.
          startDate: new Date(data[0]?.start_date)
            .toISOString()
            .substring(0, 10),
          endDate: new Date(data[0]?.end_date).toISOString().substring(0, 10),
          period: data[0].period,
        };
      } else {
        throw new Error('Unexpected response from API');
      }
    },
  });
}
