import { useQuery } from '@tanstack/react-query';
import { getKpiData } from '../services/kpiDataService';

/**
 * The handeling of api data
 *
 * @param {object} runId     // The run ID of the current run
 *
 * @return {Promise}
 */
export function useKpiData(runId) {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['useKpiData', runId],
    queryFn: async () => {
      return getKpiData(runId);
    },
    select: (data) => {
      // Make sure the response contains an array.
      if (Array.isArray(data)) {
        return data;
      } else {
        throw new Error('Unexpected response from API');
      }
    },
  });
}
