import ManageAsset from '../components/Assets/ManageAsset';
import { useAssets } from '../hooks/useAssets';
import Loading from '../components/general/Loading';
import AddAsset from '../components/Assets/AddAsset';
import { useEffect, useState } from 'react';
import { setBatteryObject, setGeneratorObject } from '../helpers/Assets';
import { deletebattery, deleteGenerator, patchBattery, patchGenerator, postBattery, postGenerator } from '../services/assetService';
import { initialBattery, initialGenerator } from '../Constants';

/**
 * The admin page
 *
 * @return {JSX.Element}
 */
export default function Admin() {
  const [generators, setGenerators] = useState([]);
  const [batteries, setBatteries] = useState([]);
  const { data: generatorData, isLoading: generatorsLoading } =
    useAssets('generator');
  const { data: batteryData, isLoading: batteriesLoading } =
    useAssets('battery');

  useEffect(() => {
    if (generatorData) {
      setGenerators(generatorData);
    }
  }, [generatorData]);

  useEffect(() => {
    if (batteryData) {
      setBatteries(batteryData);
    }
  }, [batteryData]);

  return (
    <div className="col-span-12">
      {generatorsLoading ? (
        <Loading />
      ) : (
        <section className="shadow-micro bg-white rounded-lg py-6 px-4 sm:p-10 mb-6">
          <h1 className="text-2xl leading-6 font-medium pb-2 mb-6 uppercase">
            Generators
          </h1>
          {generators.map((generator) => {
            const inputFields = [
              {
                label: 'Generator type *',
                name: 'generator-type',
                inputType: 'string',
                value: generator.type,
              },
              {
                label: 'Purchase price (€) *',
                name: 'purchase-price',
                inputType: 'number',
                value: generator.price,
              },
              {
                label: 'Operational costs per kWh (€/kWh) *',
                name: 'operational-cost',
                inputType: 'number',
                value: generator.operational_cost,
              },
              {
                label: 'emmissions CO2 (kg per hour) *',
                name: 'emmission-co2',
                inputType: 'number',
                value: generator.emmission_co2,
              },
              {
                label: 'emmissions NOx (kg per hour) *',
                name: 'emmission-nox',
                inputType: 'number',
                value: generator.emmission_NOx,
              },
              {
                label: 'Power rating (kW) *',
                name: 'power',
                inputType: 'number',
                value: generator.capacity,
              },
              {
                label: 'Generator minimal load ratio (%) *',
                name: 'load-ratio',
                inputType: 'number',
                value: generator.min_power_perc,
              },
            ];
            return (
              <ManageAsset
                key={generator.id}
                assetObject={generator}
                setAssets={setGenerators}
                inputFields={inputFields}
                setAssetObject={setGeneratorObject}
                assetLabel="generator"
                patchAsset={patchGenerator}
                deleteAsset={deleteGenerator}
              />
            );
          })}

          <AddAsset
            setAssets={setGenerators}
            setAssetObject={setGeneratorObject}
            initialAsset={initialGenerator}
            assetLabel="generator"
            postAsset={postGenerator}
          />
        </section>
      )}
      {batteriesLoading ? (
        <Loading />
      ) : (
        <section className="shadow-micro bg-white rounded-lg py-6 px-4 sm:p-10 mb-6">
          <h1 className="text-2xl leading-6 font-medium pb-2 mb-6 uppercase">
            Batteries
          </h1>
          {batteries.map((battery) => {
            const inputFields = [
              {
                label: 'Battery name *',
                name: 'battery-name',
                inputType: 'string',
                value: battery.name,
              },
              {
                label: 'Purchase price (€) *',
                name: 'purchase-price',
                inputType: 'number',
                value: battery.price,
              },
              {
                label: 'Battery capacity in kWh *',
                name: 'power',
                inputType: 'number',
                value: battery.capacity,
              },
              {
                label: 'Inverter capacity in kW *',
                name: 'inverter',
                inputType: 'number',
                value: battery.inverter_capacity,
              },
              {
                label: 'Minimal SoC (%) *',
                name: 'minimal-soc',
                inputType: 'number',
                value: battery.min_soc,
              },
              {
                label: 'Maximum SoC (%) *',
                name: 'maximum-soc',
                inputType: 'number',
                value: battery.max_soc,
              },
              {
                label: 'Discharge factor (%) *',
                name: 'discharge-factor',
                inputType: 'number',
                value: battery.dcac_efficiency,
              },
            ];
            return (
              <ManageAsset
                key={battery.id}
                assetObject={battery}
                setAssets={setBatteries}
                inputFields={inputFields}
                setAssetObject={setBatteryObject}
                assetLabel="battery"
                patchAsset={patchBattery}
                deleteAsset={deletebattery}
              />
            );
          })}

          <AddAsset
            setAssets={setBatteries}
            setAssetObject={setBatteryObject}
            initialAsset={initialBattery}
            assetLabel="battery"
            postAsset={postBattery}
          />
        </section>
      )}
    </div>
  );
}
