import { useQuery } from '@tanstack/react-query';
import { getBatteryData, getGeneratorData } from '../services/inputDataService';

/**
 * The handeling of api data
 *
 * @param {string} type // The type of asset to be used
 * @return {Promise}
 */
export function useAssets(type) {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['useAssets', type],
    queryFn: async () => {
      if (type === 'generator') {
        return getGeneratorData();
      }
      if (type === 'battery') {
        return getBatteryData();
      }
    },
  });
}
