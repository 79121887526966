import { useQuery } from '@tanstack/react-query';
import { getChartData } from '../services/chartDataService';

/**
 * The handeling of api data
 *
 * @param {object} runId     // The run ID of the current run
 * @param {object} endpoint  // The endpoint to use
 * @param {string} period    // The selected period to use
 * @param {string} startDate // The selected startDate to pass
 *
 * @return {Promise}
 */
export function useChartData(runId, endpoint, period = '', startDate = '') {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['useChartData', runId, endpoint, period, startDate],
    queryFn: async () => {
      return getChartData(runId, endpoint, period, startDate);
    },
    select: (data) => {
      // Make sure the response contains an array.
      if (Array.isArray(data)) {
        return data;
      } else {
        if (data?.detail) {
          throw new Error(data.detail);
        }

        throw new Error('Unexpected response from API');
      }
    },
  });
}
