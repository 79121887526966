import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useChartData } from "../../hooks/useChartData";
import useInputContext from "../../hooks/useInputContext";
import { theme } from "../../tailwind.config";
import DownloadChartButton from "../Buttons/DownloadChartButton";
import Loading from "../general/Loading";
import { CustomTooltip } from "../../helpers/CustomizedTooltip";

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;

  return (
    // x and y needed a little extra to align
    <g transform={`translate(${x + 25},${y + 15})`}>
      <text textAnchor="end" fill="#666">
        {payload.value > 9999
          ? `${payload.value / 1000} MWh`
          : `${payload.value} kWh`}
      </text>
    </g>
  );
};

/**
 * The BatterySoc component
 *
 * @return {JSX.Element}
 */
export default function MaxPowerDelivery() {
  const { runId } = useInputContext();
  const {
    data,
    isLoading,
    isError,
    error,
  } = useChartData(runId, 'max_power_delivery');
  const chartName = 'Max Power Delivery';
  const elements = document.querySelector('#maxpowerdelivery-chart');

  return (
    <>
      <div className="chart-container">
        <div className="flex items-center justify-between pb-8">
          <h2 className="text-xl uppercase">{chartName}</h2>
          {!isLoading && !!data.length && (
            <DownloadChartButton
              isLoading={isLoading}
              name={chartName}
              elements={elements}
            />
          )}
        </div>
        {isLoading ? (
          <Loading />
        ) : isError ? (
          <p>{error}</p>
        ) : (
          <ResponsiveContainer aspect={4 / 2}>
            <BarChart
              layout="vertical"
              data={data}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 30,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                type="number"
                label={{ position: 'bottom' }}
                tick={<CustomizedAxisTick />}
              />
              <YAxis dataKey="name" yAxisId={0} type="category" hide />
              <YAxis dataKey="name" yAxisId={1} type="category" hide />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Bar
                barSize={50}
                yAxisId={0}
                dataKey="Diesel Generator (max)"
                stackId="a"
                fill={theme.colors['micro-genset-curtailed']}
              />
              <Bar
                barSize={50}
                yAxisId={0}
                dataKey="Gas Generator (max)"
                stackId="a"
                fill={theme.colors['micro-genset-used']}
              />
              <Bar
                barSize={50}
                yAxisId={0}
                dataKey="Grid (max)"
                stackId="a"
                fill={theme.colors['micro-grid']}
              />
              <Bar
                barSize={50}
                yAxisId={0}
                dataKey="Solar"
                stackId="a"
                fill={theme.colors['micro-solar']}
              />
              <Bar
                barSize={50}
                yAxisId={1}
                dataKey="Load"
                stackId="b"
                stroke={theme.colors['micro-load-profile']}
                strokeWidth={3}
                strokeDasharray="6 6"
                fill={theme.colors['micro-load-profile']}
                fillOpacity={0}
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    </>
  );
}
