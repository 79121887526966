import { useOktaAuth } from '@okta/okta-react';
import { useQuery } from '@tanstack/react-query';
import { oktaAuth } from '../auth/oktaAuth';

/**
 * Gets the user info
 *
 * @return {array}
 */
export default function useAuthUser() {
  const { authState } = useOktaAuth();

  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['useAuthUser'],
    queryFn: () => {
      if (!authState || !authState.isAuthenticated) {
        return null;
      } else {
        return oktaAuth.token.getUserInfo();
      }
    },
  });
}
