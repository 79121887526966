import { useEffect, useState } from 'react';
import { Button } from '../Buttons/Button';

/**
 * The datepicker and (15minutes/day/month/year) filter description
 *
 * @param {string} props // Component properties
 *
 * @return {JSX.Element}
 */
export default function DatePickerFilter({
  minDate,
  maxDate,
  startDate,
  setStartDate,
  period,
  setPeriod,
  quartersAvailable,
}) {
  const [selectedStartDate, setSelectedStartDate] = useState(startDate);
  const [selectedPeriod, setSelectedPeriod] = useState(period);

  /**
   * Confirm the filter selection.
   */
  function confirmFilter() {
    setStartDate(selectedStartDate);
    setPeriod(selectedPeriod);
  }

  useEffect(() => {
    setSelectedStartDate(startDate);
    setSelectedPeriod(period);
  }, [startDate, period]);

  return (
    <>
      <div className="col-span-12 pb-4 md:col-span-4 flex md:justify-end md:items-end flex-col lg:flex-row">
        <div>
          <label
            htmlFor="date-selection"
            className="block text-sm font-univers-condensed-bold uppercase"
          >
            Start date
          </label>
          <input
            className="mt-1 block px-2 py-1 pt-1.5 text-base border border-gray200 focus:outline-none focus:border-black sm:text-sm rounded-lg font-univers"
            type="date"
            defaultValue={minDate}
            min={minDate}
            max={maxDate}
            onChange={(e) => setSelectedStartDate(e.target.value)}
          />
        </div>

        <div className="md:pl-4 mt-4 lg:mt-0">
          <label
            htmlFor="year-selection"
            className="block text-sm font-univers-condensed-bold uppercase"
          >
            Show
          </label>
          <select
            id="year-selection"
            name="year-selection"
            className="mt-1 block px-2 py-2 pt-2 text-base border border-gray200 focus:outline-none focus:border-black sm:text-sm rounded-lg font-univers"
            defaultValue={period}
            onChange={(e) => setSelectedPeriod(e.target.value)}
          >
            <option>Year</option>
            <option>Month</option>
            <option>Day-hours</option>
            {quartersAvailable && <option>Day</option>}
          </select>
        </div>
        <div className="flex md:pl-4 mt-4 lg:mt-0">
          <Button
            onClick={confirmFilter}
            className="mb-0 disabled:bg-gray300"
            disabled={
              period === selectedPeriod && startDate === selectedStartDate
            }
          >
            Confirm filter
          </Button>
        </div>
      </div>
    </>
  );
}
